import * as React from 'react';

import Footer from "./Footer";
import DocumentsNavbar from "./DocumentsNavbar";

interface IProps {
    children?: React.ReactNode
}

const DocumentsLayout = ({ children }: IProps) => {

    return (
        <React.Fragment>
            <DocumentsNavbar />
            {children}
            <Footer />
        </React.Fragment>
    );
}

export default DocumentsLayout;