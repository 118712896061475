//import * as React from 'react';
import { Navigate, useParams, useLocation } from 'react-router-dom';
//import Localization from "./Localization";
import { useTranslation } from 'react-i18next';
import { createLocalizedPath } from './Global';

const LocalizedRoute = (
    {
        component: Component,
        layout: Layout,
        ...rest
    }) => {

    const params = useParams();
    const location = useLocation();
    const { t, i18n } = useTranslation();

    let language = params.locale;
    if (language != null) {

        language = language.split('-')[0];
        if (i18n.options.supportedLngs && !i18n.options.supportedLngs.includes(language)) {
            return (<Navigate to={'/' + i18n.language.split('-')[0] + location.pathname} />);
        }
    }
    else {
        let path = createLocalizedPath(null, location.pathname);
        return (<Navigate to={path} />);
    }

    return (        
        <Layout>
            <Component {...rest} />
        </Layout>
    )
}

export default LocalizedRoute;