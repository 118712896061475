/* eslint-disable */
import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react';
//import Promise from 'promise-polyfill';
/* eslint-enable */
import Rules from './ValidationRules';

interface FormContextType {
    attachToForm: (component: any) => void;
    detachFromForm: (component: any) => void;
    instantValidate: boolean;
    debounceTime: number;
    //validate: (validator, value) => boolean;
    onChildStateChanged?: (name: string, isValid: boolean) => void;
    getValidator?: (validator: any, value: any) => any;
};

const FormContext = React.createContext<FormContextType | null>(null);

interface IProps {
    onSubmit: any,
    instantValidate: any,
    onError: any,
    debounceTime: any,
    children: any,
    validators: any
}

const ValidatorForm = forwardRef(({ onSubmit, instantValidate, onError, debounceTime, children, validators, ...rest }: IProps, ref) => {

    const [localDebounceTime, setLocalDebounceTime] = useState(debounceTime);
    const [localInstantValidate, setLocalInstantValidate] = useState(instantValidate !== undefined ? instantValidate : true);

    const localData = useRef({
        childs: []
    });

    useImperativeHandle(ref, () => ({
        isFormValid: isFormValid,
        submit: submit,
        resetValidations: resetValidations
    }));

    const attachToForm = (component) => {
        if (localData.current.childs.indexOf(component) === -1) {
            localData.current.childs.push(component);
        }
    }

    const detachFromForm = (component) => {
        const componentPos = localData.current.childs.indexOf(component);
        if (componentPos !== -1) {
            localData.current.childs = localData.current.childs.slice(0, componentPos)
                .concat(localData.current.childs.slice(componentPos + 1));
        }
    }

    const submit = (event) => {
        if (event) {
            event.preventDefault();
            event.persist();
        }
        //localData.current.errors = [];
        walk(localData.current.childs).then((result) => {
            //if (localData.current.errors.length) {
            //onError != null && onError(localData.current.errors);
            //}

            if (result) {

                return proceedGlobalRules().then(result => {
                    if (result.length == 0) //doesn't have errors
                    {
                        onSubmit(event);
                        return true;
                    }

                    return false;
                })
            }

            return result;
        });
    }

    const proceedGlobalRules = () => {

        if (validators == null || validators.length == 0) {
            return Promise.resolve([]);
        }

        //apply global parameters
        return Promise.all(localData.current.childs.map(input => { return { input: input, state: input.current.getState() } })).then((data) => {

            var fields = {};
            data.forEach(item => fields[item.state.name] = item.state.value);

            return Promise.all(validators.map(validator => validator(fields))).then((result) => {

                var merged = [].concat.apply([], result).filter(x => x != null);

                //distinct invalid values
                var hash = {}
                merged.forEach(item => {

                    if (item != null) {

                        if (hash[item.name] == null) {
                            hash[item.name] = item.message;
                        }
                    }
                });

                //apply updates
                data.forEach(item => {
                    if (hash[item.state.name] != null) {
                        item.input.current.setState(false, hash[item.state.name]);
                    } else {
                        item.input.current.setState(true);
                    }
                });

                return merged;
            });
        });
    }

    const walk = (children, dryRun = false) => {
        return Promise.all(children.map(input => validateChild(input, dryRun))).then((data) => {
            return (data.indexOf(false) == -1);
        });
    }

    const validateChild = (input, dryRun) => {
        return input.current.validate(dryRun).then((result) => {
            //localData.current.errors.push({ target: input, ...result });
            return result.isValid;
        });
    }

    const getValidator = (validator, value) => {
        let result = null;
        if (typeof (validator) == 'function') {
            result = validator(value);
        }
        else {
            let name = validator;
            let extra;

            //console.log('ValidatorForm validator name=' + name);

            const splitIdx = validator.indexOf(':');
            if (splitIdx !== -1) {
                name = validator.substring(0, splitIdx);
                extra = validator.substring(splitIdx + 1);
            }

            //                console.log('ValidatorForm value: ' + value + ' extra: ' + extra);

            //                if (name == 'maxDate' || name == 'minDate') {
            //                    console.log('date value: ' + new Date(value.toString()) + ' border value: ' + new Date(Date.parse(extra)));
            //                }

            result = Rules[name](value, extra);
        }
        return result;
    }

    const onChildStateChanged = (name, isValid) => {

        //nothing to do here
        if (validators == null || validators.length == 0)
            return;

        var childsState = localData.current.childs.map(input => input.current.getState())

        //var index = childsState.findIndex(x => x.name == name);

        if (!isValid) //we update global rules if some of fields becomes invalid
        {
            Promise.all(localData.current.childs.map(input => input.current.setState(true)));

        } else {

            //i was the only one invlid child
            if (childsState.every(child => child.name != name ? child.isValid : true)) {
                proceedGlobalRules();
            }
        }
    }
    /*
    const find = (collection, fn) => {
        for (let i = 0, l = collection.length; i < l; i++) {
            const item = collection[i];
            if (fn(item)) {
                return item;
            }
        }
        return null;
    }
    */
    const resetValidations = () => {
        localData.current.childs.forEach((child) => {
            child.current.cancelValidation();
            child.current.setState(true, null);
        });
    }

    const isFormValid = (dryRun = true) => walk(localData.current.childs, dryRun);

    const context = {
        attachToForm: attachToForm,
        detachFromForm: detachFromForm,
        onChildStateChanged: onChildStateChanged,
        instantValidate: localInstantValidate,
        debounceTime: localDebounceTime,
        getValidator: getValidator
    };

    return (
        <FormContext.Provider value={context}>
            <form {...rest} onSubmit={submit}>
                {children}
            </form>
        </FormContext.Provider>
    );
})

export default ValidatorForm;
export type { FormContextType };
export { FormContext };