export default {
    name: 'pathDetector',

    lookup(options) {

        // options -> are passed in options
        var path = location.pathname.split('/').filter((x: string) => x != '');
        if (path.length == 0)
            return null;

        if (path[0].length == 2)
            return path[0];

        return null;
    },

    cacheUserLanguage(lng, options) {
        // options -> are passed in options
        // lng -> current language, will be called after init and on changeLanguage
        // store it
    },
};