import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { createLanguageLinks } from "../Global";
import Navbar from "./Navbar";
import Title from "./Title";
import Purpose from "./Purpose";
import ParkingObjects from "./ParkingObjects";
import Contacts from "./Contacts";
import Presentation from "./Presentation";

const Home = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();

    const purpose = useRef();
    const objects = useRef();
    const contacts = useRef();
    const presentation = useRef();

    const navbarHeight = useRef(0);

    useEffect(() => {
        var navBar = document.getElementById("navbar");
        if (navBar) {
            var domRect = navBar.getBoundingClientRect();
            navbarHeight.current = domRect.height;
        }

    }, []);

    const onShowMore = () => {
        scrolling(purpose);
    }

    const scrolling = (instance: any) => {

        if (instance?.current == null)
            return;

        //console.log("move to id: " + instance.current.props.id);
        let offset = instance.current.offsetTop - navbarHeight.current;
        if (offset < 0)
            offset = 0;

        window.scrollTo({
            top: offset,
            behavior: "smooth"
        });
    }

    const handleCallback = (name: string) => {

        let language = i18n.language.split('-')[0];
        navigate(`/${language}/${name}`);
        window.scrollTo({top: 0});
    }

    return (
        <div>
            <Helmet
                link={createLanguageLinks('')}>

                <title>{t('AutoparkMainTitle')}</title>
                <meta name="description" content={t('AutoparkMainDescription')} />
                <meta name="keywords" content={t('AutoparkMainKeywords')} />
            </Helmet>

            <Navbar purposeRef={purpose} objectsRef={objects} contactsRef={contacts} presentationRef={presentation} scrolling={scrolling} />
            <Title
                onShowMore={onShowMore}
            />
            <Purpose
                ref={purpose}
                callback={handleCallback}
            />
            <ParkingObjects
                ref={objects}
            />
            <Presentation
                ref={presentation}
            />
            <Contacts
                ref={contacts}
            />
        </div>
    );
}


export default Home;
