import * as React from "react";
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import { Theme } from '@mui/material';
import { default as Grid } from '@mui/material/Unstable_Grid2'; // Grid version 2
import styles from '../styles/theme.scss';
import DownloadSvg from "./DownloadSvg";
import Localization from "../Localization";
import { useTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';
import { createLanguageLinks } from "../Global";

const useStyles = makeStyles((theme: Theme) => ({

    container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',

        [theme.breakpoints.down('sm')]: {
            margin: '70px 0 0 0'
        },
        [theme.breakpoints.up('sm')]: {
            margin: '70px 0 0 0',
            padding: '0 3rem 0 3rem'
        }
    },
    subContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        paddingTop: '1rem',

        [theme.breakpoints.down('sm')]: {
            paddingLeft: '0',
            paddingRight: '0'
        }
    },
    header: {
        fontWeight: '600',

        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
            paddingTop: '0'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '3rem',
            paddingTop: '3rem'
        }
    },
    textLeft: {
        textAlign: 'left',
        fontWeight: 'bold'
    },
    icon: {
        display: 'flex',
        justifyContent: 'flex-end'
    }

}));

const Documents = () => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();

    return (
        <Container maxWidth={false} className={classes.container}>

            <Helmet
                link={createLanguageLinks('/documents')}>

                <title>{t('AutoparkDocuments')}</title>
                <meta name="description" content={t('AutoparkDocumentsDescription')} />
                <meta name="keywords" content={t('AutoparkDocumentsKeywords')} />
            </Helmet>

            <div className={classes.header}>
                {t('Documentation')}
            </div>

            <Container max-width="sm" className={classes.subContainer}>

                <Grid container spacing={2}>
                    <Grid xs={2} md={4} className={classes.icon}>
                        <a href="/files/Жизненный цикл ПО.pdf">
                            <DownloadSvg width="30" height="30" />
                        </a>
                    </Grid>
                    <Grid xs={10} md={8}>
                        <span className={classes.textLeft}>
                            {t('SoftLifecycle')}
                        </span>
                    </Grid>

                    <Grid xs={2} md={4} className={classes.icon}>
                        <a href="/files/Интеграционное API.pdf">
                            <DownloadSvg width="30" height="30" />
                        </a>
                    </Grid>
                    <Grid xs={10} md={8}>
                        <span className={classes.textLeft}>
                            {t('IntegrationAPI')}
                        </span>
                    </Grid>
                    {/*
                    <Grid xs={4} className={classes.icon}>
                        <a href="/files/Инструкция инженера пусконаладки.pdf">
                            <DownloadSvg width="30" height="30" />
                        </a>
                    </Grid>
                    <Grid xs={8}>
                        <span className={classes.textLeft}>
                            {t('EngineersManual')}
                        </span>
                    </Grid>
                    */}
                    <Grid xs={2} md={4} className={classes.icon}>
                        <a href="/files/Краткое описание запуска системы Autopark.pdf">
                            <DownloadSvg width="30" height="30" />
                        </a>
                    </Grid>
                    <Grid xs={10} md={8}>
                        <span className={classes.textLeft}>
                            {t('SoftwareStartupShortDescription')}
                        </span>
                    </Grid>

                    <Grid xs={2} md={4} className={classes.icon}>
                        <a href="/files/Описание программного комплекса Autopark.pdf">
                            <DownloadSvg width="30" height="30" />
                        </a>
                    </Grid>
                    <Grid xs={10} md={8}>
                        <span className={classes.textLeft}>
                            {t('SoftwareDescription')}
                        </span>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
}

export default Documents;