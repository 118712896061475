/* eslint-disable */
import React, { useState, useEffect, useContext, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
//import Promise from 'promise-polyfill';
/* eslint-enable */
//import { polyfill } from 'react-lifecycles-compat';
import { FormContext, FormContextType } from './ValidatorForm';
import { debounce } from 'throttle-debounce';

const useFormValidation = (
    disabled,
    name,
    value,
    errorMessages,
    validators
) => {

    const [isValid, setIsValid] = useState(true);
    const [isGlobalValid, setIsGlobalValid] = useState(true);

    const [localValue, setLocalValue] = useState(value);
    const [localErrorMessages, setLocalErrorMessages] = useState(errorMessages);
    const [localValidators, setLocalValidators] = useState(validators);

    const [globalErrorMessages, setGlobalErrorMessages] = useState(errorMessages);

    const context: FormContextType = useContext(FormContext);

    const localData = useRef({
        instantValidate: true,
        invalid: [],
        debounceTime: null
    });

    const handle = useRef(null);

    useEffect(() => {

        if (context?.attachToForm) {
            context.attachToForm(handle);
            localData.current.instantValidate = context.instantValidate;
            localData.current.debounceTime = context.debounceTime;
        }

        return () => {
            if (context?.detachFromForm) {
                context.detachFromForm(handle);
            }
            //localData.current.validateDebounced.cancel();
            validateDebounced.cancel();
        };
    }, []);

    useEffect(() => {

        if (localValidators !== validators || localErrorMessages !== errorMessages) {
            setLocalValidators(validators);
            setLocalErrorMessages(errorMessages);
        }

        if (localValue != value) {
            setLocalValue(value);

            if (localData.current.instantValidate) {
                /*localData.current.*/validateDebounced(false, value); //because localValue is not applied here
            }
        }

    }, [value, errorMessages, validators]);

    const getErrorMessage = () => {

        if (!isValid) {
            const type = typeof localErrorMessages;

            if (type === 'string') {
                return localErrorMessages;
            } else if (type === 'object') {

                if (localData.current.invalid.length > 0) {
                    return localErrorMessages[localData.current.invalid[0]];
                }
            }
            else {
                // eslint-disable-next-line
                console.log('unknown errorMessages type=' + type + '; ', errorMessages);
            }
        } else if (!isGlobalValid) {
            return globalErrorMessages;
        }

        return null;
    }

    const validate = (dryRun = false, value = undefined) => {

        if (disabled || localValidators == null || localValidators.length == 0) {

            if (!dryRun) {
                setIsValid(true);

                if (localData.current.instantValidate) {
                    context.onChildStateChanged(name, true);
                }
            }

            return Promise.resolve({ name: name, isValid: true });
        }

        var currentValue = value === undefined ? localValue : value;
        const validations = Promise.all(
            localValidators.map(validator => context.getValidator(validator, currentValue)),
        );

        let valid = true;

        return validations.then((results) => {
            localData.current.invalid = [];
            results.forEach((result, key) => {
                if (!result) {
                    valid = false;
                    localData.current.invalid.push(key);
                }
            });

            if (!dryRun) {
                setIsValid(valid);

                if (localData.current.instantValidate) {
                    context.onChildStateChanged(name, valid);
                }
            }

            return { name: name, isValid: valid };
        });
    }

    const validateDebounced = debounce(localData.current.debounceTime, validate);

    const getState = () => {
        return { name: name, value: localValue, isValid: isValid };
    }

    const setState = (valid, message) => {

        //if (valid == isGlobalValid)
            //return;

        setIsGlobalValid(valid);
        if (valid)
            setGlobalErrorMessages(null);
        else
            setGlobalErrorMessages(message);

        setIsValid(valid);
    }

    useImperativeHandle(handle, () => ({
        validate: (dryRun, value) => validate(dryRun, value),
        cancelValidation: () => validateDebounced.cancel(),
        getState: () => getState(),
        setState: (isValid, message) => setState(isValid, message)
    }));

    return { isValid: isValid && isGlobalValid, errorMessage: getErrorMessage() };
}

export default useFormValidation;