/* eslint-disable */
import React from 'react';
import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment, Tooltip } from "@mui/material";
/* eslint-enable */
import useFormValidation from './ValidationForm/useFormValidation';
//import TooltipInfoLabel from '../../../components/shared/TooltipInfoLabel';
//import ErrorTooltip from '../../../components/shared/ErrorTooltip';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    tooltip: {
        display: 'flex',
        width: '100%'
    }
});

const TextValidator = ({
    name,
    errorMessages,
    validators,
    tooltip,
    tooltipLink,
    tooltipButtonName,
    onLinkClick,
    readOnly,
    InputProps,
    value,
    disabled,

    ...rest
}) => {

    const { isValid, errorMessage } = useFormValidation(disabled, name, value, errorMessages, validators);
    const classes = useStyles();

    return (
        <TextField
            name={name}
            value={value}
            variant="standard"
            error={!isValid}
            disabled={disabled}
            InputProps={{
                readOnly: !!readOnly
                //endAdornment: (
                //    <InputAdornment position="end">
                //        {InputProps?.endAdornment}
                //        {(!isValid && (
                //            <ErrorTooltip content={errorMessage} />
                //        ))}
                //    </InputAdornment>
                //)
            }}
            {...rest}
        />
    );
}

export default TextValidator;