import * as React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RedirectRoute from './RedirectRoute';
import Layout from './components/Layout';
import Home from './components/Home';
import Documents from './components/Documents';
import NotFoundPage from './components/NotFoundPage';
import ErrorPage from './components/ErrorPage';
import LocalizedRoute from './LocalizedRoute';
import DocumentsLayout from './components/DocumentsLayout';
import Stability from './components/Stability';
import Simplicity from './components/Simplicity';
import Flexibility from './components/Flexibility';
import Functionality from './components/Functionality';
import Reports from './components/Reports';

export default () => {

    const { t, i18n } = useTranslation();

    return (
        <Routes>

            <Route path='/:locale'>
                <Route path='' element={<LocalizedRoute layout={Layout} component={Home} />} />
                <Route path='documents' element={<LocalizedRoute layout={DocumentsLayout} component={Documents} />} />

                <Route path='stability' element={<LocalizedRoute layout={DocumentsLayout} component={Stability} />} />
                <Route path='simplicity' element={<LocalizedRoute layout={DocumentsLayout} component={Simplicity} />} />
                <Route path='flexibility' element={<LocalizedRoute layout={DocumentsLayout} component={Flexibility} />} />
                <Route path='functionality' element={<LocalizedRoute layout={DocumentsLayout} component={Functionality} />} />
                <Route path='reports' element={<LocalizedRoute layout={DocumentsLayout} component={Reports} />} />

                <Route path='errorPage' element={<LocalizedRoute layout={DocumentsLayout} component={ErrorPage} />} />

                <Route path='*' element={<LocalizedRoute layout={DocumentsLayout} component={NotFoundPage} />} />
            </Route>
            {/*
            <Route path='/' element={<Navigate to={i18n.language.split('-')[0]} />}></Route>
            */}
            <Route path='*' element={<LocalizedRoute layout={DocumentsLayout} component={NotFoundPage} />} />
        </Routes>
    )
};
