import * as React from "react";
import saluyt from "../img/SalyutHotel.jpg";
import musicHouse from "../img/HouseOfMusic.jpg";
import museumOfTechnics from "../img/MuseumOfTechnics.jpg";
import tishinka from "../img/Tishinka.jpg";
import tcn1 from "../img/tc1korolev.jpg";
import moonRover from "../img/MoonRover.png";
import status from "../img/status.jpg";
import PropTypes from "prop-types";
import Localization from "../Localization";
import { makeStyles, Theme } from '@mui/styles';
import { default as Grid } from '@mui/material/Unstable_Grid2'; // Grid version 2
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import styles from '../styles/theme.scss';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({

    container: {
        [theme.breakpoints.down('sm')]: {
            padding: '0.5rem',
            backgroundColor: styles.primaryColor2_30
        },
        [theme.breakpoints.up('sm')]: {
            padding: '3rem',
            backgroundColor: styles.primaryColor2_30
        }
    },
    activeParkings: {
        fontSize: '3rem',
        fontWeight: '600'
    },
    objectsList: {
        paddingTop: '3rem'
    },
    grid: {
        //flexGrow: '1'
    },
    card: {
        display: 'flex',
        height: '250px'
    },
    cardImage: {
        flexShrink: '0',
        flexGrow: '1',
        width: 'auto',
        maxWidth: '100%',
        height: 'auto',
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    header: {
        alignSelf: 'flex-start',
        fontSize: '2rem',
        fontWeight: 'bold'
    }
}));


const ParkingObjects = React.forwardRef((props, ref) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();

    return (
        <Container
            ref={ref}
            maxWidth="false"
            className={classes.container}>

            <div>
                <span className={classes.activeParkings}>
                    {t('SomeOfParkings')}
                </span>
            </div>

            <Grid container spacing={2} className={classes.objectsList}>

                <Grid xs={12} sm={6} md={4} lg={3} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardContent className={classes.flex}>
                            <Box className={classes.header}>
                                <span>{t('HotelSalute')}</span>
                            </Box>

                            <img className={classes.cardImage} src={saluyt} alt={t('HotelSalute')} />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid xs={12} sm={6} md={4} lg={3} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardContent className={classes.flex}>
                            <Box className={classes.header}>
                                <span>{t('HouseOfMusic')}</span>
                            </Box>
                            <img className={classes.cardImage} src={musicHouse} alt={t('HouseOfMusic')} />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid xs={12} sm={6} md={4} lg={3} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardContent className={classes.flex}>
                            <Box className={classes.header}>
                                <span>{t('MuseumOfTechnics')}</span>
                            </Box>
                            <img className={classes.cardImage} src={museumOfTechnics} alt={t('MuseumOfTechnics')} />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid xs={12} sm={6} md={4} lg={3} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardContent className={classes.flex}>
                            <Box className={classes.header}>
                                <span>{t('ShoppingComplexTishinka')}</span>
                            </Box>
                            <img className={classes.cardImage} src={tishinka} alt={t('ShoppingComplexTishinka')} />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid xs={12} sm={6} md={4} lg={3} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardContent className={classes.flex}>
                            <Box className={classes.header}>
                                <span>{t('TCNumber1')}</span>
                            </Box>
                            <img className={classes.cardImage} src={tcn1} alt={t('TCNumber1')} />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid xs={12} sm={6} md={4} lg={3} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardContent className={classes.flex}>
                            <Box className={classes.header}>
                                <span>{t('TCMoonRover')}</span>
                            </Box>
                            <img className={classes.cardImage} src={moonRover} alt={t('TCMoonRover')} />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid xs={12} sm={6} md={4} lg={3} className={classes.grid}>
                    <Card className={classes.card}>
                        <CardContent className={classes.flex}>
                            <Box className={classes.header}>
                                <span>{t('TCStatus')}</span>
                            </Box>
                            <img className={classes.cardImage} src={status} alt={t('TCStatus')} />
                        </CardContent>
                    </Card>
                </Grid>
                {/*
                <Grid sm={6} md={4} lg={3}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Box>
                                <span>{t('YourName')}</span>
                            </Box>
                            <h4>{t('PlaceForYourLogo')}</h4>
                        </CardContent>
                    </Card>
                </Grid>
                */}
            </Grid>
        </Container>
    );
});

export default ParkingObjects;