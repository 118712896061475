import * as React from "react";
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material';
import { default as Grid } from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { createLanguageLinks } from "../Global";

const useStyles = makeStyles((theme: Theme) => ({

    container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',

        [theme.breakpoints.down('sm')]: {
            margin: '70px 0 0 0'
        },
        [theme.breakpoints.up('sm')]: {
            margin: '70px 0 0 0',
            padding: '0 3rem 0 3rem'
        }
    },
    subContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        paddingTop: '1rem',

        [theme.breakpoints.down('sm')]: {
            paddingLeft: '0',
            paddingRight: '0'
        }
    },
    header: {
        fontWeight: '600',

        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
            paddingTop: '0'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '3rem',
            paddingTop: '3rem'
        }
    },
    text: {
        textIndent: '1.5rem',
        fontSize: '1.5rem',

        [theme.breakpoints.down('sm')]: {
            marginTop: '0',
            marginBottom: '0',
        }
    },
    list: {
        fontSize: '1.5rem',
        '& li::marker': {
            content: '\'⮚\'',
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '1rem',
        paddingBottom: '1rem'
    }
}));

const Reports = React.forwardRef((props, ref) => {

    const navigate = useNavigate();
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const handleBackButtonClick = () => {
        navigate(-1);
    }

    return (

        <Container maxWidth={false} className={classes.container}>

            <Helmet
                link={createLanguageLinks('/reports')}>

                <title>{t('AutoparkReports')}</title>
                <meta name="description" content={t('AutoparkReportsDescription')} />
                <meta name="keywords" content={t('AutoparkReportsKeywords')} />
            </Helmet>

            <div className={classes.header}>
                {t('StatisticsAndReports')}
            </div>

            <Container max-width="md" className={classes.subContainer}>

                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <p className={classes.text}>{ t("Reports_Info1") }
                        </p>

                        <p className={classes.text}>
                            {t("Reports_Info2")}
                        </p>
                        <ol className={classes.list}>
                            <li>{t("Reports_Info2_List1")}</li>
                            <li>{t("Reports_Info2_List2")}</li>
                        </ol>
                        <p className={classes.text}>
                            {t("Reports_Info3")}
                        </p>

                    </Grid>

                    <Grid xs={12} className={classes.buttonContainer}>
                        <Button name="flexibility" type="button" variant="outlined" onClick={handleBackButtonClick}>{'❮' + ' ' + t("Back")}</Button>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
});

export default Reports;