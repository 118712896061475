import * as React from "react";
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { default as Grid } from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({

    container: {
        [theme.breakpoints.down('sm')]: {
            padding: '0.5rem',
            minHeight: '1200px'
        },
        [theme.breakpoints.up('sm')]: {
            padding: '3rem',
            minHeight: '1200px'
        }
    },

    /*
    header: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: '1rem'
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: '2rem'
        }
    },
    */

    subHeader: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: '2rem'
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: '3rem'
        }
    },

    brief: {
        //fill: styles.primaryColor1,
        display: 'flex',
        alignItems: 'flex-start',
        fontWeight: '600',
        fontSize: '2rem',
        lineHeight: '2rem',
        justifyContent: 'flex-end',

        [theme.breakpoints.down('lg')]: {
            paddingBottom: '1rem'
        },
        //[theme.breakpoints.down('sm')]: {
            //justifyContent: 'flex-start'
        //},
        [theme.breakpoints.up('sm')]: {
            textAlign: 'end',
        },
    },

    explain: {
        //fill: styles.primaryColor1,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'stretch',
        fontSize: '1.5rem',
    },

    whyAutopark: {
        fontSize: '3rem',
        fontWeight: '600'
    },
    buttonContainer: {
        display: 'flex',

        [theme.breakpoints.down('lg')]: {
            justifyContent: 'flex-start',
            paddingTop: '1rem'
        },
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'flex-end'
        },
        alignItems: 'center'
    }

}));

interface IProps {
    callback: (name: string) => void;
}

const Purpose = React.forwardRef((props: IProps, ref) => {

    const navigate = useNavigate();
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { callback } = props;

    const handleMoreButtonClick = (event: any) => {
        callback(event.target.name);
    }

    return (
        <Container
            maxWidth={false}
            id="purpose"
            ref={ref}
            className={classes.container}>

            <div>
                <span className={classes.whyAutopark}>
                    {t('WhyAutopark')}
                </span>
            </div>

            <Grid container columnSpacing={{ lg: 3 }}>

                <Grid container xs={12} className={classes.subHeader}>
                    <Grid lg={3} className={classes.brief}>
                        {t('Stability')}
                    </Grid>
                    <Grid lg={7} className={classes.explain}>
                        ⮚ {t("Purpose_Info1") }
                    </Grid>
                    <Grid md={2} lg={2} xl={1} className={classes.buttonContainer}>
                        <Button name="stability" type="button" variant="outlined" onClick={handleMoreButtonClick}>{t("More") + ' ' + '❯'}</Button>
                    </Grid>
                </Grid>

                <Grid container xs={12} className={classes.subHeader}>
                    <Grid lg={3} className={classes.brief}>
                        {t('Simplicity')}
                    </Grid>
                    <Grid container lg={7}>
                        <Grid lg={12} className={classes.explain}>
                            ⮚ {t("Purpose_Info2")}
                        </Grid>
                        <Grid lg={12} className={classes.explain}>
                            ⮚ {t("Purpose_Info3")}
                        </Grid>
                    </Grid>
                    <Grid md={2} lg={2} xl={1} className={classes.buttonContainer}>
                        <Button name="simplicity" type="button" variant="outlined" onClick={handleMoreButtonClick}>{t("More") + ' ' + '❯'}</Button>
                    </Grid>
                </Grid>

                <Grid container xs={12} className={classes.subHeader}>
                    <Grid lg={3} className={classes.brief}>
                        {t('Flexibility')}
                    </Grid>
                    <Grid container lg={7}>
                        <Grid lg={12} className={classes.explain}>
                            ⮚ {t("Purpose_Info4")}
                        </Grid>
                        <Grid lg={12} className={classes.explain}>
                            ⮚ {t("Purpose_Info5")}
                        </Grid>
                    </Grid>
                    <Grid md={2} lg={2} xl={1} className={classes.buttonContainer}>
                        <Button name="flexibility" type="button" variant="outlined" onClick={handleMoreButtonClick}>{t("More") + ' ' + '❯'}</Button>
                    </Grid>
                </Grid>

                <Grid container xs={12} className={classes.subHeader}>
                    <Grid lg={3} className={classes.brief}>
                        {t('Functionality')}
                    </Grid>
                    <Grid container lg={7}>
                        <Grid lg={12} className={classes.explain}>
                            ⮚ {t("Purpose_Info6")}
                        </Grid>
                        <Grid lg={12} className={classes.explain}>
                            ⮚ {t("Purpose_Info7")}
                        </Grid>
                        <Grid lg={12} className={classes.explain}>
                            ⮚ {t("Purpose_Info8")}
                        </Grid>
                        <Grid lg={12} className={classes.explain}>
                            ⮚ {t("Purpose_Info9")}
                        </Grid>
                    </Grid>
                    <Grid md={2} lg={2} xl={1} className={classes.buttonContainer}>
                        <Button name="functionality" type="button" variant="outlined" onClick={handleMoreButtonClick}>{t("More") + ' ' + '❯'}</Button>
                    </Grid>
                </Grid>

                <Grid container xs={12} className={classes.subHeader}>
                    <Grid lg={3} className={classes.brief}>
                        {t('StatisticsAndReports')}
                    </Grid>
                    <Grid lg={7} className={classes.explain}>
                        ⮚ {t("Purpose_Info10")}
                    </Grid>
                    <Grid md={2} lg={2} xl={1} className={classes.buttonContainer}>
                        <Button name="reports" type="button" variant="outlined" onClick={handleMoreButtonClick}>{t("More") + ' ' + '❯'}</Button>
                    </Grid>
                </Grid>

            </Grid>

        </Container>
    );
});

export default Purpose;