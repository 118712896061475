import Result from "../models/Result";
import Axios, { AxiosRequestConfig, AxiosError } from "axios";
import jsonToUrl from "json-to-url";

/**
 * Represents base class of the isomorphic service.
 */
export default class ServiceBase {

    /**
     * Make request with JSON data.
     * @param opts
     */
    static async requestJson(opts: any) {

        var axiosResult = null;
        var result = null;

        //opts.url = transformUrl(opts.url); // Allow requests also for the Node.

        var processQuery = (url: any, data: any) => {
            if (data) {
                return `${url}?${jsonToUrl(data)}`;
            }
            return url;
        };

        var axiosRequestConfig;

        //if (isNode()) {
        // Make SSR requests 'authorized' from the NodeServices to the web server.
        //axiosRequestConfig = {
        //headers: {
        //Cookie: Globals.getSession().private.cookie
        //}
        //}
        //}
        //else {
        axiosRequestConfig = {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: false
        }

        /*
        var user = Globals.user;
        if (user != null && user.token != null) {
            axiosRequestConfig.headers["Authorization"] = "Bearer " + user.token;
        }
        */

        try {
            switch (opts.method) {
                case "GET":
                    axiosResult = await Axios.get(processQuery(opts.url, opts.data), axiosRequestConfig);
                    break;
                case "POST":
                    axiosResult = await Axios.post(opts.url, opts.data, axiosRequestConfig);
                    break;
                case "PUT":
                    axiosResult = await Axios.put(opts.url, opts.data, axiosRequestConfig);
                    break;
                case "PATCH":
                    axiosResult = await Axios.patch(opts.url, opts.data, axiosRequestConfig);
                    break;
                case "DELETE":
                    axiosResult = await Axios.delete(processQuery(opts.url, opts.data), axiosRequestConfig);
                    break;
                default:
                    throw "Unknown request type!";
            }
            result = new Result(axiosResult.data.value, null, ...axiosResult.data.errors);
        } catch (error: any) {
            result = new Result(null, error.response.status, error.message);
        }

        /*
        if (!result.isDenied && result.hasErrors) {
            Ui.showErrors(...result.errors);
        }

        if (result.isDenied) {
            //clear session
            Globals.reset();
            //redirect at start page
            Globals.redirect("/login");
            //store.dispatch(push('/login'));
        }
        */

        return result;
    }

    /**
     * Allows you to send files to the server.
     * @param opts
     */
    static async sendFormData(opts: any) {
        var axiosResult = null;
        var result = null;

        //opts.url = transformUrl(opts.url); // Allow requests also for Node.

        var axiosOpts = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        try {
            switch (opts.method) {
                case "POST":
                    axiosResult = await Axios.post(opts.url, opts.data, axiosOpts);
                    break;
                case "PUT":
                    axiosResult = await Axios.put(opts.url, opts.data, axiosOpts);
                    break;
                case "PATCH":
                    axiosResult = await Axios.patch(opts.url, opts.data, axiosOpts);
                    break;
                default:
                    throw "Unknown request type!";
            }
            result = new Result(axiosResult.data.value, null, ...axiosResult.data.errors);
        } catch (error: any) {
            result = new Result(null, error.message);
        }
        /*
        if (result.hasErrors) {
            Ui.showErrors(...result.errors);
        }
        */

        return result;
    }
}