import * as React from 'react';
//import { Container } from 'reactstrap';

//adding my imports
//import Navbar from "./navbar.jsx";
import Footer from "./Footer";
//import 'bootstrap/dist/css/bootstrap.css';
//import "../styles/index.scss";
//import '../index.css';

interface IProps
{
    children?: React.ReactNode
}

const Layout = (props: IProps) => {

    return (
        <React.Fragment>
            {props.children}
            <Footer />
        </React.Fragment>
    );
}

export default Layout;