//import * as React from 'react';
import { Navigate, useParams, useLocation } from 'react-router-dom';
//import Localization from "./Localization";
import { useTranslation } from 'react-i18next';
import i18n from './Localization';

export const createLanguageLinks = (url) => {

    var language = i18n.language.split('-')[0];

    return [
        { "rel": "canonical", "href": "https://www.autoparksoft.com/" + language + url },
        { "rel": "alternate", "href": "https://www.autoparksoft.com" + url, hrefLang: "x-default" },
        { "rel": "alternate", "href": "https://www.autoparksoft.com/ru" + url, hrefLang: "ru" },
        { "rel": "alternate", "href": "https://www.autoparksoft.com/en" + url, hrefLang: "en" }
    ]
}

export const createLocalizedPath = (lng: string, pathname: string) => {

    let language = lng != null ? lng.split('-')[0] : i18n.language.split('-')[0];

    let path = pathname.split('/').filter((x: string) => x != '');
    if (path.length > 0) {

        //always
        var locale = path[0];
        if (i18n.options.supportedLngs && i18n.options.supportedLngs.includes(locale)) {
            path.shift();
        }
    }

    var fullPath = '/' + language;
    if (path.length != 0)
        fullPath += '/' + path.join('/');

    return fullPath;
}