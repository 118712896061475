import * as React from 'react';
import Localization from "../Localization";
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import { default as Grid } from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	container: {
		paddingTop: '70px',
		flexGrow: '1'
	},
	flexCenter: {
		display: 'flex',
		justifyContent: 'center'
	},
	textCenter: {
		textAlign: 'center'
	}
}));

const ErrorPage = () => {

	const classes = useStyles();
	const { t, i18n } = useTranslation();

	return (
		<Container className={classes.container} >

			<Grid container className={classes.flexCenter}>
				<Grid xs={12} className={classes.flexCenter}>
					<h1 className={classes.textCenter}>
						{t('RequestHandleError')}
					</h1>
				</Grid>
				<Grid xs={12} className={classes.flexCenter}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="200" height="200">
						<path d="M505.229,247.505c-4.34-10.24-10.54-19.43-18.449-27.33c-7.9-7.91-17.091-14.109-27.33-18.45
			c-10.601-4.489-21.851-6.77-33.45-6.77h-0.021c-10.359,0-20.51,1.84-30.16,5.48c-7.639,2.872-14.775,6.816-21.299,11.714
			L70.452,36.568c-5.205-3.006-11.269-3.805-17.074-2.248c-5.805,1.556-10.657,5.278-13.662,10.482L28.464,64.287
			c-3.005,5.205-3.804,11.269-2.249,17.073c1.555,5.806,5.278,10.657,10.482,13.663L408.87,309.926
			c3.645,2.112,8.217,0.774,10.239-2.739c2.071-3.584,0.851-8.17-2.729-10.24l-0.01-0.01l-46.44-26.811
			c17.73-4.75,41.95-11.24,51.22-13.729l13.971,8.069c5.77,3.33,9.37,9.561,9.38,16.26c-0.019,6.321-3.168,11.912-7.87,15.24
			c-3.429,2.461-4.136,7.121-1.76,10.46c2.359,3.338,7.034,4.195,10.439,1.78c0.01,0,0.01-0.01,0.021-0.01
			c8.993-6.398,14.17-16.733,14.17-27.471c0-11.976-6.328-23.144-16.88-29.25l-53.686-31.002
			c11.107-6.822,23.936-10.51,37.066-10.518c18.92,0,36.74,7.4,50.18,20.82c13.42,13.439,20.82,31.26,20.82,50.18v182.5H355v-168.85
			c0-4.141-3.37-7.5-7.5-7.5c-4.141,0-7.5,3.359-7.5,7.5v176.35c0,1.98,0.8,3.91,2.2,5.3c1.39,1.4,3.319,2.2,5.3,2.2h157
			c1.97,0,3.91-0.8,5.3-2.2c1.4-1.39,2.2-3.33,2.2-5.3v-190C512,269.355,509.72,258.105,505.229,247.505z M44.199,82.033
			c-1.735-1.002-2.976-2.619-3.495-4.554c-0.518-1.936-0.252-3.956,0.75-5.69l11.251-19.485c1.002-1.735,2.619-2.977,4.554-3.495
			c1.937-0.52,3.956-0.251,5.691,0.75l47.541,27.453L59.266,90.734L44.199,82.033z M77.636,101.341l51.226-13.722l40.087,23.147
			l-51.227,13.723L77.636,101.341z M136.094,135.097l51.227-13.722l40.088,23.149l-51.226,13.722L136.094,135.097z M194.552,168.853
			l51.227-13.722l40.088,23.147L234.64,192L194.552,168.853z M253.01,202.609l51.226-13.723l40.088,23.149l-51.227,13.722
			L253.01,202.609z M402.782,245.791l-51.227,13.723l-40.088-23.148l51.228-13.723l8.59,4.96c0.04,0.024,0.08,0.046,0.12,0.069
			L402.782,245.791z"/>
						<path d="M64.496,273.457H37.5c-20.678,0-37.5,16.822-37.5,37.5v160c0,4.143,3.358,7.5,7.5,7.5h86.996c4.142,0,7.5-3.357,7.5-7.5
			v-160C101.996,290.279,85.173,273.457,64.496,273.457z M86.996,463.457H15v-152.5c0-12.406,10.093-22.5,22.5-22.5h26.996
			c12.407,0,22.5,10.094,22.5,22.5V463.457z"/>
						<path d="M64.496,304.432H37.5c-4.142,0-7.5,3.357-7.5,7.5v26.995c0,4.143,3.358,7.5,7.5,7.5c4.142,0,7.5-3.357,7.5-7.5v-19.495
			h11.996v19.495c0,4.143,3.358,7.5,7.5,7.5c4.142,0,7.5-3.357,7.5-7.5v-26.995C71.996,307.789,68.638,304.432,64.496,304.432z"/>
						<path d="M426,324.611c-17.921,0-32.5,14.579-32.5,32.5c0,17.921,14.579,32.5,32.5,32.5c17.921,0,32.5-14.579,32.5-32.5
			C458.5,339.19,443.921,324.611,426,324.611z M426,374.611c-9.649,0-17.5-7.85-17.5-17.5c0-9.649,7.851-17.5,17.5-17.5
			s17.5,7.851,17.5,17.5S435.649,374.611,426,374.611z"/>
					</svg>
				</Grid>
				<Grid xs={12} className={classes.flexCenter}>
					<h4 className={classes.textCenter}>
						{t('RequestHandleError_Info')}
					</h4>
				</Grid>
			</Grid>
		</Container>
	);
}

export default ErrorPage;