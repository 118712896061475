import * as React from "react";
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import { Theme } from '@mui/material';
import Button from '@mui/material/Button';
//import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { default as Grid } from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { createLanguageLinks } from "../Global";

const useStyles = makeStyles((theme: Theme) => ({

    container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',

        [theme.breakpoints.down('sm')]: {
            margin: '70px 0 0 0'
        },
        [theme.breakpoints.up('sm')]: {
            margin: '70px 0 0 0',
            padding: '0 3rem 0 3rem'
        }
    },
    subContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        paddingTop: '1rem',

        [theme.breakpoints.down('sm')]: {
            paddingLeft: '0',
            paddingRight: '0'
        }
    },
    header: {
        fontWeight: '600',

        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
            paddingTop: '0'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '3rem',
            paddingTop: '3rem'
        }
    },
    text: {
        textIndent: '1.5rem',
        fontSize: '1.5rem',

        [theme.breakpoints.down('sm')]: {
            marginTop: '0',
            marginBottom: '0',
        }
    },
    list: {
        fontSize: '1.5rem',
        '& li::marker': {
            content : '\'⮚\'',
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '1rem',
        paddingBottom: '1rem'
    }
}));

const Functionality = React.forwardRef((props, ref) => {

    const navigate = useNavigate();
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const handleBackButtonClick = () => {
        navigate(-1);
    }

    return (

        <Container maxWidth={false} className={classes.container}>

            <Helmet
                link={createLanguageLinks('/functionality')}>

                <title>{t('AutoparkFunctionalityTitle')}</title>
                <meta name="description" content={t('AutoparkFunctionalityDescription')} />
                <meta name="keywords" content={t('AutoparkFunctionalityKeywords')} />
            </Helmet>

            <div className={classes.header}>
                {t('Functionality')}
            </div>

            <Container max-width="md" className={classes.subContainer}>

                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <p className={classes.text}>{t("Functionality_Info1")}</p>

                        <p className={classes.text}>
                            {t("Functionality_Info2")}
                        </p>
                        <ol className={classes.list}>
                            <li>{t("Functionality_Info2_List1")}</li>
                            <li>{t("Functionality_Info2_List2")}</li>
                            <li>{t("Functionality_Info2_List3")}</li>
                            <li>{t("Functionality_Info2_List4")}</li>
                        </ol>

                        <p className={classes.text}>
                            {t("Functionality_Info3")}
                        </p>
                        <ol className={classes.list}>
                            <li>{t("Functionality_Info3_List1")}</li>
                            <li>{t("Functionality_Info3_List2")}</li>
                            <li>{t("Functionality_Info3_List3")}</li>
                            <li>{t("Functionality_Info3_List4")}</li>
                            <li>{t("Functionality_Info3_List5")}</li>
                        </ol>


                        <p className={classes.text}>
                            {t("Functionality_Info4")}
                        </p>

                        <p className={classes.text}>
                            {t("Functionality_Info7")}
                        </p>

                        <p className={classes.text}>
                            {t("Functionality_Info5")}
                        </p>

                        <p className={classes.text}>
                            {t("Functionality_Info6")}
                        </p>
                        <ol className={classes.list}>
                            <li>{t("Functionality_Info6_List1")}</li>
                            <li>{t("Functionality_Info6_List2")}</li>
                            <li>{t("Functionality_Info6_List3")}</li>
                            <li>{t("Functionality_Info6_List4")}</li>
                            <li>{t("Functionality_Info6_List5")}</li>
                            <li>{t("Functionality_Info6_List6")}</li>
                        </ol>
                    </Grid>
                    {/*
                    <Grid xs={12}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Printers</TableCell>
                                        <TableCell>Scanners</TableCell>
                                        <TableCell>Card readers</TableCell>
                                        <TableCell>Card dispensers</TableCell>
                                        <TableCell>Bill validators</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {devices.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="right">{row.calories}</TableCell>
                                            <TableCell align="right">{row.fat}</TableCell>
                                            <TableCell align="right">{row.carbs}</TableCell>
                                            <TableCell align="right">{row.protein}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    */}

                    <Grid xs={12} className={classes.buttonContainer}>
                        <Button name="flexibility" type="button" variant="outlined" onClick={handleBackButtonClick}>{'❮' + ' ' + t("Back")}</Button>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
});

export default Functionality;