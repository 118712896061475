import ServiceBase from "./ServiceBase";

export default class CommonService extends ServiceBase {

    static async SendEmail(email: any) {
        var result = await this.sendFormData({
            url: "api/Common/SendEmail",
            method: "POST",
            data: email
        });

        return result;
    }
}