import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import styles from '../styles/theme.scss';
//import { hexToRGBA } from '../utilities';


const mainTheme = createTheme({
    palette: {
        mode: "light",

        primary: {
            main: styles.primaryColor1,
            //light: '#e3f2fd',
            //dark: '#42a5f5',
            //contrastText: 'rgba(0, 0, 0, 0.87)'
        },
        secondary: {
            main: styles.primaryColor2,
            //light: '#f3e5f5',
            //dark: '#ab47bc',
            //contrastText: 'rgba(0, 0, 0, 0.87)'
        },

        /*
        error: {
            main: '#f44336',
            //light: '#e57373',
            //dark: '#d32f2f',
            //contrastText: '#fff'
        },

        warning: {
            main: '#ffa726',
            //light: '#ffb74d',
            //dark: '#f57c00',
            //contrastText: 'rgba(0, 0, 0, 0.87)'
        },

        info: {
            main: '#29b6f6',
            //light: '#4fc3f7',
            //dark: '#0288d1',
            //contrastText: 'rgba(0, 0, 0, 0.87)'
        },

        success: {
            main: '#66bb6a',
            //light: '#81c784',
            //dark: '#388e3c',
            //contrastText: 'rgba(0, 0, 0, 0.87)'
        }
        */
        text: {
            primary: styles.primaryColor3
        },

        background: {
            default: 'white'
        }
    },

    components: {

        MuiCssBaseline: {
            styleOverrides: {
                //"@font-face": {
                //fontFamily: "Inspiration",
                //src: `url(${InspirationTTF}) format("truetype")`
                //},
                /*
                html: {
                    ...breakpoints
                }
                */
                /*
                body: {
                    ...breakpoints
                }
                */
            }
        },

        MuiButton: {

            styleOverrides: {

                root: {
                    //...breakpoints,

                    fontWeight: 'bold',
                    fontFamily: styles.fontFamilyBold,
                    /*
                    "&.Mui-selected": {
                        color: styles.primaryColor1
                    },
                    "&:hover": {
                        backgroundColor: hexToRGBA(styles.primaryColor3, 0.08)
                    }
                    */
                },
                /*
                text: {
                    color: 'red'
                }
                */
            }
        },

        //    MuiToggleButton: {
        //
        //        styleOverrides: {
        //            root: {
        //                //...breakpoints,
        //
        //                borderColor: styles.primaryColor3,
        //                /*
        //                "&.Mui-selected": {
        //                    color: styles.primaryColor1
        //                },
        //                */
        //                "&:hover": {
        //                    backgroundColor: hexToRGBA(styles.primaryColor3, 0.08)
        //                }
        //            }
        //        }
        //    },
        //    MuiTooltip: {
        //        styleOverrides: {
        //            tooltip:
        //            {
        //                fontSize: '16px',
        //                border: '1px solid ' + hexToRGBA(styles.primaryColor3, 0.3),
        //                backgroundColor: styles.primaryColor1
        //            },
        //            arrow: {
        //                "&:before": {
        //                    backgroundColor: styles.primaryColor1
        //                },
        //            }
        //        }
        //    },
        //    MuiDialog: {
        //        styleOverrides: {
        //            paper: {
        //                border: '1px solid ' + styles.primaryColor3,
        //                backgroundColor: styles.primaryColor2,
        //                backgroundImage: "none"
        //            }
        //        }
        //    },
        MuiPaper: {
            styleOverrides: {
                root: {
                    color: styles.primaryColor2,
                    backgroundColor: styles.primaryColor3
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white'
                }
            }
        }
        //    MuiAccordionDetails: {
        //        styleOverrides: {
        //            root: {
        //                padding: 0
        //            }
        //        }
        //    },
        //    MuiTableCell:
        //    {
        //        styleOverrides: {
        //            root: {
        //                padding: '4px'
        //            }
        //        }
        //    }
        //
        //    /*
        //    MuiSelect: {
        //        styleOverrides: {
        //            select: {
        //                ...breakpoints
        //            }
        //        }
        //    }
        //    */
    }
});

export default mainTheme;