import { useEffect, useState } from "react";
import { Globals, useSpring, useChain, useSpringRef, animated, easings } from '@react-spring/web';
import Button from '@mui/material/Button';
import { Theme, useMediaQuery } from '@mui/material';
import Localization from "../Localization";
import { makeStyles } from '@mui/styles';
import styles from '../styles/theme.scss';
import Image from '../img/underground_parking.jpg'; // Import using relative path
import { useTranslation } from 'react-i18next';
import { useTheme } from "@emotion/react";

const useStyles = makeStyles((theme: Theme) => ({

    background: {
        overflow: 'hidden',
        height: '100vh',
        background: `url(${Image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%'
    },

    leftContainer: {
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            color: styles.primaryColor2,
            top: '15%',
            left: '30%',
            transform: 'translate(-50%, -50%)'
        },
        [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            color: styles.primaryColor2,
            top: '15%',
            left: '30%',
            transform: 'translate(-50%, -50%)'
        }
    },

    topContainer: {
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            color: styles.primaryColor2,
            top: '30%',
            left: '50%',
            textTransform: 'uppercase',
            transform: 'translate(-50%, -50%)'
        },
        [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            color: styles.primaryColor2,
            top: '25%',
            left: '50%',
            textTransform: 'uppercase',
            transform: 'translate(-50%, -50%)'
        }
    },

    centerQuestion: {
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            fontFamily: styles.fontFamilyBold,
            color: styles.primaryColor2,
            fontSize: '8rem',
            lineHeight: '8rem',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        },
        [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            fontFamily: styles.fontFamilyBold,
            color: styles.primaryColor2,
            fontSize: '8rem',
            lineHeight: '8rem',
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }
    },

    centerContainer: {
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            top: '65%',
            left: '50% ',
            transform: 'translate(-50%, -50%)'
        },
        [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            top: '50%',
            left: '50% ',
            transform: 'translate(-50%, -50%)'
        }
    },

    /*
    .work - button {
        margin - top: 1rem;
        padding: .2rem 2rem;
        //background-color: $color-primary;
        //color: white;
        border - radius: 2rem;
        border: 1px $color - secondary solid;
        outline: none;
        -moz - outline - style: none;
        font - size: 1.2rem;
        font - weight: 600;
    
        &:hover {
            //background-color: $color-secondary;
            //color: $color-ternary;
            cursor: pointer;
        }
    }
    
    .work - button:focus {
        outline: 0;
    }
    */

    textCenter: {
        textAlign: 'center'
    }

}));

interface IProps {
    onShowMore: () => void;
}

const Title = ({ onShowMore }: IProps) => {

    const theme: Theme = useTheme();
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const isSXScreen = useMediaQuery(theme.breakpoints.down('sm'));
    //var skipAnimation = sessionStorage.getItem("skipAnimation") == 'true';

    const leftLabelRef = useSpringRef();
    const leftLabel = useSpring({
        ref: leftLabelRef,
        from: { x: 0, opacity: 0 },
        to: [
            { x: 100, opacity: 1 },
            { delay: 2000, opacity: 0 },
        ],
        onRest: () => setFirstTimeAnimation(false),
        config: {
            mass: 1,
            friction: 120,
            tension: 280,
            //clamp: true
        },
    });

    const mainLabelRef = useSpringRef();
    const mainLabel = useSpring({
        ref: mainLabelRef,
        from: { opacity: 0, scale: 1 },
        to: [
            { opacity: 1 },
            { delay: 2000, scale: isSXScreen ? 1.5 : 2 }
        ],
        //onRest: () => { sessionStorage.setItem("skipAnimation", "true"); },
        config: {
            mass: 1,
            friction: 120,
            tension: 280,
            //clamp: true
        }
    });

    const questionLabelRef = useSpringRef();
    const questionLabel = useSpring({
        ref: questionLabelRef,
        from: { opacity: 0 },
        to: [
            { opacity: 1 },
            { delay: 500, opacity: 0 }
        ],
        config: {
            mass: 1,
            friction: 120,
            tension: 280,
            //clamp: true
        }
    });

    const buttonLabelRef = useSpringRef();
    const buttonLabel = useSpring({
        ref: buttonLabelRef,
        from: { scale: 0 },
        to: { scale: 1 },
        config: {
            mass: 1,
            friction: 120,
            tension: 280,
            //clamp: true
        }
    });

    const [firstTimeAnimation, setFirstTimeAnimation] = useState(true);

    useChain(firstTimeAnimation ? [leftLabelRef, mainLabelRef, questionLabelRef, buttonLabelRef] : [], [0, 1, 1.5, 2], 1000);
    //Globals.assign({ skipAnimation: skipAnimation });

    return (
        <div id="background" className={classes.background}>
            <div className={classes.leftContainer}>
                <animated.div
                    style={leftLabel}
                >
                    <h2>
                        {t('WhyDoYouNeed')}
                    </h2>
                </animated.div>
            </div>

            <div className={classes.topContainer}>
                <animated.div
                    style={mainLabel}
                >
                    <h1 className={classes.textCenter}>
                        {t('APS')}
                    </h1>
                </animated.div>
            </div>

            <animated.div
                style={questionLabel}
            >
                <span className={classes.centerQuestion}>?</span>
            </animated.div>
            <div className={classes.centerContainer}>
                <animated.div
                    style={buttonLabel}
                >
                    <Button
                        //className="work-button"
                        variant="contained"
                        color="secondary"
                        onClick={onShowMore}>
                        {t('ShowMore')}
                    </Button>
                </animated.div>
            </div>
        </div>
    );
}

export default Title;