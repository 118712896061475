import * as React from "react";
import Localization from "../Localization";
import Container from '@mui/material/Container';
import { makeStyles } from '@mui/styles';
import styles from '../styles/theme.scss';
import { default as Grid } from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: '1rem',
        backgroundColor: styles.primaryColor3
    },
    emailContainer: {
        color: styles.primaryColor1,
        paddingTop: '1rem',
        display: 'flex',
        justifyContent: 'center'
    },
    email: {
        color: styles.primaryColor1
    },
    nameContainer: {
        paddingTop: '1rem',
        display: 'flex',
        justifyContent: 'center'
    },
    name: {
        color: styles.primaryColor1,
        fontWeight: 'bold'
    },
    flex: {
        display: 'flex',
        justifyContent: 'center'
    },
    paddingRight: {
        paddingRight: '1rem'
    }
}));

const Footer = () => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();

    return (
        <Container maxWidth="false" className={classes.container}>
            <Container maxWidth="sm">
                <Grid container>
                    <Grid xs={12} className={classes.flex}>
                        <a
                            className={classes.paddingRight}
                            href="https://www.facebook.com/profile.php?id=100006416868267"
                            target="_blank"
                            rel="noopener noreferrer">
                            <svg className="animated animate-pulse" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                                <path d="M241.871,256.001 C249.673,256.001 256,249.675 256,241.872 L256,14.129 C256,6.325 249.673,0 241.871,0 L14.129,0 C6.324,0 0,6.325 0,14.129 L0,241.872 C0,249.675 6.324,256.001 14.129,256.001 L241.871,256.001" fill="#395185"></path>
                                <path d="M176.635,256.001 L176.635,156.864 L209.912,156.864 L214.894,118.229 L176.635,118.229 L176.635,93.561 C176.635,82.375 179.742,74.752 195.783,74.752 L216.242,74.743 L216.242,40.188 C212.702,39.717 200.558,38.665 186.43,38.665 C156.932,38.665 136.738,56.67 136.738,89.736 L136.738,118.229 L103.376,118.229 L103.376,156.864 L136.738,156.864 L136.738,256.001 L176.635,256.001" fill="#FFFFFF"></path>
                            </svg>
                        </a>
                        <a
                            href="https://www.linkedin.com/in/sergey-shimyanov-0a96328b"
                            target="_blank"
                            rel="noopener noreferrer">

                            <svg className="animated animate-pulse" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg" width="32" height="32">
                                <path d="M8,72 L64,72 C68.418278,72 72,68.418278 72,64 L72,8 C72,3.581722 68.418278,-8.11624501e-16 64,0 L8,0 C3.581722,8.11624501e-16 -5.41083001e-16,3.581722 0,8 L0,64 C5.41083001e-16,68.418278 3.581722,72 8,72 Z" id="Rounded" fill="#007EBB"></path>
                                <path d="M62,62 L51.315625,62 L51.315625,43.8021149 C51.315625,38.8127542 49.4197917,36.0245323 45.4707031,36.0245323 C41.1746094,36.0245323 38.9300781,38.9261103 38.9300781,43.8021149 L38.9300781,62 L28.6333333,62 L28.6333333,27.3333333 L38.9300781,27.3333333 L38.9300781,32.0029283 C38.9300781,32.0029283 42.0260417,26.2742151 49.3825521,26.2742151 C56.7356771,26.2742151 62,30.7644705 62,40.051212 L62,62 Z M16.349349,22.7940133 C12.8420573,22.7940133 10,19.9296567 10,16.3970067 C10,12.8643566 12.8420573,10 16.349349,10 C19.8566406,10 22.6970052,12.8643566 22.6970052,16.3970067 C22.6970052,19.9296567 19.8566406,22.7940133 16.349349,22.7940133 Z M11.0325521,62 L21.769401,62 L21.769401,27.3333333 L11.0325521,27.3333333 L11.0325521,62 Z" fill="#FFFFFF"></path>
                            </svg>
                        </a>
                    </Grid>
                    <Grid xs={12} className={classes.emailContainer}>
                        <a className={classes.email} href="mailto:support@autoparksoft.com">support@autoparksoft.com</a>
                    </Grid>
                    <Grid xs={12} className={classes.nameContainer}>
                        <span className={classes.name}>{t('Shimyanov')} &copy; {new Date().getFullYear()}</span>
                    </Grid>
                </Grid >
            </Container>
        </Container >
    );
}

export default Footer;
