import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { default as PathLanguageDetector } from "./PathLanguageDetector";

// translations are already at
// '../public/locales/en/translation.json'
// which is the default for the xhr backend to load from
const languageDetector = new LanguageDetector();
languageDetector.addDetector(PathLanguageDetector);

i18n
    .use(languageDetector)
    .use(backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        load: 'languageOnly',
        fallbackLng: "en", // use en if detected lng is not available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },

        detection: {
            // order and from where user language should be detected
            order: ['pathDetector', 'navigator'],

            // cache user language on
            //caches: ['localStorage', 'cookie'],
            //excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

            // optional expire and domain for set cookie
            //cookieMinutes: 10,
            //cookieDomain: 'myDomain',

            // optional htmlTag with lang attribute, the default is:
            //htmlTag: document.documentElement,

            // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
            //cookieOptions: { path: '/', sameSite: 'strict' }
        },
        supportedLngs: ["en", "ru"]
    });

export default i18n;