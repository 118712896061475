import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import CommonService from "../services/CommonService";
import Localization from "../Localization";
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Theme } from '@mui/material';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { default as Grid } from '@mui/material/Unstable_Grid2'; // Grid version 2
import styles from '../styles/theme.scss';
import ValidatorForm from './Form/ValidationForm/ValidatorForm';
import TextValidator from './Form/TextValidator';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({

    container: {
        [theme.breakpoints.down('sm')]: {
            padding: '0.5rem',
            backgroundColor: styles.primaryColor2_30
        },
        [theme.breakpoints.up('sm')]: {
            padding: '3rem',
            backgroundColor: styles.primaryColor2_30
        }
    },
    formContainer: {
        [theme.breakpoints.down('sm')]: {
            padding: '0'
        }
    },
    label: {
        fontSize: '3rem',
        fontWeight: '600'
    },
    paper: {

        backgroundColor: styles.whiteColor,
        padding: '1rem'
    },
    field: {
        paddingTop: '1rem'
    },
    actionArea: {
        paddingTop: '1rem',
        display: 'flex',
        justifyContent: 'center'
    },
    header: {
        textAlign: 'center'
    }

}));

const Contacts = React.forwardRef((props, ref) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();


    const [showModal, setShowModal] = useState(false);
    const [success, setSuccess] = useState(false);
    const [disabledSend, setDisabledSend] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const sendFormRef = useRef();

    const handleSubmit = (e: any) => {
        e.preventDefault();

        setDisabledSend(true);

        var data = new FormData();
        data.append('name', name);
        data.append('email', email);
        data.append('message', message);

        CommonService.SendEmail(data).then((result) => {

            if (result.hasErrors === false) {

                setName('');
                setEmail('');
                setMessage('');

                setSuccess(true);
                setShowModal(true);

                setTimeout(() => sendFormRef.current.resetValidations(), 0);
            }
            else {
                setSuccess(false);
                setShowModal(true);
            }
        })
    };

    const handleClose = () => {
        setShowModal(false);
        setDisabledSend(false);
    }

    const handleNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setName(evt.target.value);
    }
    const handleEmailChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(evt.target.value);
    }
    const handleMessageChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(evt.target.value);
    }

    return (
        <Container ref={ref} maxWidth="false" className={classes.container}>

            <Dialog open={showModal} onClose={handleClose}>
                <DialogContent>
                    <h4>
                        {success === true ? t("MessageSentSuccessfully") : t("SendErrorTryAgainLater")}
                    </h4>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleClose}>{t('Close')}</Button>
                </DialogActions>
            </Dialog>

            <div className={classes.label}>
                {t('Contacts')}
            </div>

            <Container maxWidth="sm" className={classes.formContainer}>

                <h4 className={classes.header}>
                    {t('GotQuestions')}
                </h4>

                <Paper className={classes.paper}>
                    <ValidatorForm ref={sendFormRef} onSubmit={handleSubmit}>

                        <Grid container>
                            <Grid xs={12}>
                                <TextValidator
                                    fullWidth
                                    name="name"
                                    id="name"
                                    variant="outlined"
                                    value={name}
                                    onChange={handleNameChange}
                                    placeholder={t('Name')}
                                    validators={['required']}
                                    errorMessages = { [t('FieldCannotBeEmpty')]}
                                />
                            </Grid>

                            <Grid xs={12}>
                                <TextValidator
                                    className={classes.field}
                                    fullWidth
                                    type="email"
                                    name="email"
                                    id="email"
                                    variant="outlined"
                                    value={email}
                                    onChange={handleEmailChange}
                                    placeholder={t('YourEmail')}
                                    validators={['required', 'isEmail']}
                                    errorMessages={[t('FieldCannotBeEmpty'), t('IncorrectEmail')]}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <TextValidator
                                    className={classes.field}
                                    fullWidth
                                    type="textarea"
                                    name="message"
                                    id="message"
                                    variant="outlined"
                                    multiline
                                    value={message}
                                    onChange={handleMessageChange}
                                    placeholder={t('Message')}
                                    validators={['required']}
                                    errorMessages={[t('FieldCannotBeEmpty')]}
                                />
                            </Grid>

                            <Grid xs={12} className={classes.actionArea}>
                                <Button
                                    type="submit"
                                    variant='contained'
                                    disabled={disabledSend}>
                                    {t('Send')}
                                </Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </Paper>
            </Container>
        </Container>
    );
});

export default Contacts;
