import * as React from "react";
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { default as Grid } from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({

    container: {
        [theme.breakpoints.down('sm')]: {
            padding: '0.5rem'
            //minHeight: '1200px'
        },
        [theme.breakpoints.up('sm')]: {
            padding: '3rem'
            //minHeight: '1200px'
        }
    },

    /*
    contentContainer: {
        paddingTop: '2rem'
    },
    */

    subHeader: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: '2rem'
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: '3rem'
        }
    },

    brief: {
        //fill: styles.primaryColor1,
        display: 'flex',
        alignItems: 'flex-start',
        fontSize: '1.5rem',
        lineHeight: '1.5rem',

        [theme.breakpoints.down('lg')]: {
            paddingBottom: '1rem'
        },
    },

    header: {
        paddingTop: '3rem',
        fontSize: '3rem',
        fontWeight: '600'
    },
    numbers: {

        fontSize: '2rem',
        fontWeight: '600',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end'
    }

}));

interface IProps {
}

const Presentation = React.forwardRef((props: IProps, ref) => {

    const navigate = useNavigate();
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    return (
        <Container
            maxWidth={false}
            id="presentation"
            ref={ref}
            className={classes.container}>

            <div>
                <span className={classes.header}>
                    {t('Presentation')}
                </span>
            </div>

            <Grid container columnSpacing={{ lg: 3 }}>

                <Grid container xs={12} className={classes.subHeader}>
                    <Grid lg={6} className={classes.brief}>
                        {t("Presentation_Info1")}<br /><br />{t("Presentation_Info2")}
                    </Grid>
                    <Grid lg={6} className={classes.numbers}>
                        <div>
                            {t("Presentation_Info3")}
                        </div>
                        <div>
                            {t("Presentation_Info4")}
                        </div>
                    </Grid>
                </Grid>
            </Grid>

        </Container>
    );
});

export default Presentation;